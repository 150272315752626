<template>
  <a-layout class="index animated fadeIn">
    <a-card>
      <div class="mb15">
        <span class="mr15">
          <a-button type="primary" v-has:categoryCreate="() => categoryCreate()">
            新增类目
          </a-button>
        </span>
      </div>
      <a-form
        ref="form"
        :model="search"
        :style="{ marginBottom: '20px' }"
        :initialValues="search"
        :onFinish="pageSearchChange"
        layout="inline"
      >
        <a-form-item label="关键词" name="keywords">
          <a-input
            v-model:value="search.keywords"
            :style="{ width: '250px' }"
            placeholder="请输入类目名称"
          ></a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" htmlType="submit">搜索</a-button>
        </a-form-item>
      </a-form>
      <a-table
        :pagination="false"
        :loading="listLoading"
        bordered
        rowKey="id"
        :columns="columns"
        :data-source="data"
      >
        <template v-slot:status="{ record }">
          <a-switch
            :checkedValue="1" :unCheckedValue="0"
            checked-children="正常"
            v-model:checked="record.status"
            un-checked-children="禁用"
            v-authAction:categoryStatus="(e) => cateDisable(e, record)"
          />
        </template>
        <template v-slot:action="{ record }">
          <a-dropdown-button>
            <a class="ant-dropdown-link"> 操作</a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item>
                  <a v-has:categoryEdit="() => categoryEdit(record)" href="javascript:;"
                    >修改</a
                  >
                </a-menu-item>
                <a-menu-item>
                  <a
                    v-has:categoryDelete="() => categoryDelete(record)"
                    href="javascript:;"
                    >删除</a
                  >
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
      <a-pagination
        :style="{ marginTop: '20px', textAlign: 'right' }"
        show-size-changer
        show-quick-jumper
        :current="search.page"
        :page-size="search.limit"
        :total="total"
        :show-total="total => `总数: ${total} `"
        @change="pageCurrentChange"
        @showSizeChange="pageSizeChange"
      />
    </a-card>
  </a-layout>
  <a-modal
    title="类目"
    v-model:visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    destroyOnClose
  >
    <a-form
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 10 }"
      ref="formCell"
      :model="createForm"
      :rules="rules"
    >
      <a-form-item label="类目名称:" name="title" ref="title">
        <a-input v-model:value="createForm.title" placeholder="请输入类目名称" />
      </a-form-item>
      <a-form-item label="排序:" name="sort" ref="sort">
        <a-input-number
          v-model:value="createForm.sort"
          placeholder="请输入类目排序"
          style="width: 100%"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { ref, onMounted, reactive, toRefs, inject } from "vue";

import { setPageState, getPageState } from "@/utils/pageState";
import { $iscode, getRouter } from "@/utils/app";
import { getCate, deleteCate, statusCate, editCate, cretateCate } from "@/api/system";
// 初始化默认筛选项数值
let defSearch = {
  keywords: "",
  level: undefined,
  page: 1,
  limit: 10,
};
export default {
  components: {},
  setup(props) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    const state = reactive({
      listLoading: false,
      visible: false,
      total: 1,
      valueRadio: null,
      confirmLoading: false,
      createForm: {
        title: "",
        sort: 0,
        id: null,
      },
      rules: {
        title: [{ required: true, message: "请输入名称" }],
        sort: [{ required: true, message: "请输入排序" }],
      },
    });
    let columns = [
      {
        title: "类目ID",
        dataIndex: "id",
        key: "id",
        width:120
      },
      {
        title: "类目名称",
        dataIndex: "title",
        key: "title",
      },
       {
        title: "类目排序",
        dataIndex: "sort",
        key: "sort",
        width:120
      },
      {
        title: "类目状态",
        key: "status",
        slots: { customRender: "status" },
        width:120
      },
      {
        title: "操作",
        key: "action",
        slots: { customRender: "action" },
        width:120
      },
    ];
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 页面筛选项重置
    const pageSearchReset = () => {
      search.value = {
        ...defSearch,
        page: search.value.page,
        size: search.value.size,
      };
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, size) => {
      search.value.page = 1;
      search.value.size = size;
      initData(search.value);
    };
    const initData = async (values) => {
      state.listLoading = true;
      data.value = [];
      let res = await getCate(values)
        .then((res) => res.data)
        .catch((error) => error);
      if ($iscode(res)) {
        data.value = res.data.data;
        state.total = res.data.total;
      }
      state.listLoading = false;
    };
    // 状态修改
    const cateDisable = async (e, value) => {
      var status = value.status
      statusCate(value.id, status).then((res) => {
        if ($iscode(res.data, true)) {
          initData(search.value);
        }
      });
    };
    // 定义表单REF
    const formCell = ref();
    const categoryCreate = () => {
      state.visible = true;
      state.createForm = {
        title: "",
        sort: 0,
        id: null,
      };
    };  
    // 修改类目
    const categoryEdit = async (value) => {
      state.createForm["id"] = value.id;
      state.createForm["sort"] = value.sort;
      state.createForm["title"] = value.title;
      state.visible = true;
    };
    const $Modal = inject("$Modal");
    // 删除类目
    const categoryDelete = (value) => {
      $Modal.confirm({
        content: "确认删除所选类目",
        centered:true,
        onOk: () => {
          deleteCate(value.id).then((res) => {
            if ($iscode(res.data, true)) {
              initData(search.value);
            }
          });
        },
      });
    };
    const $message = inject("$message");
    // 添加或者修改提交信息
    const handleOk = async (e) => {
      state.confirmLoading = true;
      formCell.value
        .validate()
        .then(async (res) => {
          var result;
          if (!state.createForm.id) {
            result = await cretateCate({ ...state.createForm })
              .then((res) => res.data)
              .catch((error) => error);
          } else {
            result = await editCate(state.createForm.id, {
              ...state.createForm,
            })
              .then((res) => res.data)
              .catch((error) => error);
          }
          if ($iscode(result, true)) {
            initData(search.value);
            state.visible = false;
          }
          state.confirmLoading = false;
        })
        .catch((error) => {
          state.confirmLoading = false;
          $message.error("请完善表单");
        });
    };
    onMounted(() => {
      // 查看是否有留存状态，有则替换
      let pageState = getPageState(getRouter().route.path);
      if (pageState) {
        search.value = Object.assign(search.value, pageState);
      }
      initData(search.value);
    });
    return {
      ...toRefs(state),
      search,
      data,
      columns,
      categoryDelete,
      pageSearchChange,
      pageSearchReset,
      pageCurrentChange,
      pageSizeChange,
      categoryEdit,
      handleOk,
      cateDisable,
      formCell,
      categoryCreate,
    };
  },
};
</script>
